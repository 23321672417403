import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../Layout/DataTable/DataTable";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";

import CautionAlert from "../../utils/CautionAlert";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import Loader from "../../UI/Loader/Loader";
import { checkPrivilage } from "../../utils/checkPrivilage";
import columns from "./../../Resources/sitesColumns";
import Theme from "../../Theme/Theme";
import { DropdownButton } from "react-bootstrap";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";

const SiteListing = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const canCreate = checkPrivilage("SITES", "CREATE");
  const canUpdate = checkPrivilage("SITES", "UPDATE");
  const canDelete = checkPrivilage("SITES", "DELETE");

  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = useState("");
  const [switchItem, setSwitchItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/all/sites?includeIdmaster=true`,
      withCredentials: true,
    })
      .then(async (response) => {
        if (response.data?.data) {
          var dt = response.data?.data;

          dt = await Promise.all(
            dt.map(async (item) => {
              let obj = {
                ...item,
                action: (
                  <Fragment>
                    <style type="text/css">
                      {`
                          .ActionButton button {
                            background-color:${Theme.palette.primaryColor.backgroundColor};
                          }
                          .ActionButton button:hover {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                          .ActionButton button:focus {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                      `}
                    </style>
                    <DropdownButton
                      align="end"
                      title="Actions"
                      id="dropdown-menu-align-start"
                      className="ActionButton"
                      disabled={item?.id_master?.length === 7}
                    >
                      <ActionButton
                        content="Add"
                        onClickAction={() => idMasterHandler(item)}
                        icon="emojione-monotone:id-button"
                        eventKey="1"
                      />
                    </DropdownButton>
                  </Fragment>
                ),
              };

              return obj;
            })
          );
          setData(dt);
          setLoader(false);
        } else {
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        }
      })
      .catch((e) => {
        console.log(e);

        setApiError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "#",
      iconName: "bi:building",
      name: "Id Master",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  const idMasterHandler = async (site) => {
    setLoader(true);
    await axios({
      method: "POST",
      url: `${backendUrl}/${site.site_name}/api/id_master/all`,
      withCredentials: true,
    })
      .then(async (response) => {
        setLoader(false);
        setSuccess("Id master has been successfully added");
        loadData();
      })
      .catch(() => {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  return (
    <Fragment>
      <Loader open={loader} />

      {/* <CautionAlert
        severity="error"
        open={open}
        content="Are you sure you want to delete this item?"
        onCloseActionB={deleteConfirm}
        onCloseActionA={handleClose}
        okText="Delete"
        cancelText="Cancel"
      />
       */}
      <CautionAlert
        severity="error"
        open={error}
        content={error}
        onCloseActionB={() => setError(null)}
        okText="Ok"
        oneButton={true}
      />
      <CautionAlert
        severity="error"
        open={apiError}
        content={apiError}
        onCloseActionB={retry}
        onCloseActionA={() => setApiError(null)}
        okText="Retry"
        cancelText="Dismiss"
      />
      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />
      {location.state?.showAlert && (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/sites-management", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      )}

      <Breadcrumb options={breadCrumbData} />

      {showTable ? (
        <>
          <PageHeader left={"Sites"} />

          {data && (
            <DataTable data={data} columns={columns.idMasterSiteColumn} />
          )}
        </>
      ) : null}
    </Fragment>
  );
};

export default SiteListing;
